import React from 'react';
import {Form, Formik, FormikErrors, FormikHelpers, FormikTouched, FormikValues} from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import styles from './FormBuilder.module.css';
import {ErrorHandler, InputDate} from 'components';
import { MuiTelInput } from 'mui-tel-input'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    GridProps,
    InputLabel,
    Link,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SvgIconProps,
    Switch,
    TextField as InputText,
    TextField,
    TextFieldProps as InputTextProps,
    Typography,
} from '@mui/material';
import MuiPhoneInput from 'material-ui-phone-number';
import {useTranslation} from 'react-i18next';
import {Autocomplete} from "@mui/lab";
import {FileUploader} from "../FileUploader";
import {AutocompleteMultipleSolo} from "../AutocompleteMultipleSolo";
import ColorPicker from "../ColorPicker";
import AsyncAutocomplete, {AsyncAutocompleteProps} from "../AsyncAutocomplete";
import {Moment} from "moment";
import {light} from "@mui/material/styles/createPalette";


export const get_input_sx = (color: 'light' | 'dark' = 'light') => ({
    mb: 2,
    mt: 0,
    '& label': {
        fontSize: 14,
        transform: 'translate(14px,12px) scale(1)',
        color: color !== 'dark' ? '#ffffff' : '#B8B8B8',
        // opacity: 1
    },
    '& input': {
        padding: '12px 19px',
        color: color !== 'dark' ? '#ffffff' : 'text.primary',
        fontSize: 14,
        minHeight: '45px',
        boxSizing: 'border-box'
    },
    '& legend span': {
        fontSize: 11
    },
    '& label.Mui-focused, label.MuiFormLabel-filled': {
        transform: 'translate(16px, -8px) scale(.75)'
    },
    '& label.Mui-focused': {
        color: color !== 'dark' ? '#ffffff' : 'primary.main',
    },
    '& label.MuiFormLabel-filled': {
        // opacity:  color !== 'dark' ? .5 : 1,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: color !== 'dark' ? '#FFFFFF!important' : '#B8B8B8',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: '2px!important',
        borderColor: color !== 'dark' ? '#FFFFFF' : 'primary.main!important',
    },
})


export interface FormClasses {
    readonly root?: string;
}

export interface MultiSelectOption {
    id: string | number;
    label: string | number;
    data?: any;
}


export interface SelectOption {
    readonly value?: string | number;
    readonly title?: string | number;
}

export interface RadioOption {
    readonly value?: string | number | boolean;
    readonly title?: string | number;
    readonly subtitle?: React.ReactNode;
}

export interface FormInput {
    readonly name: string;
    readonly type?:
        | 'text'
        | 'typography'
        | 'number'
        | 'auto_complete'
        | 'async_auto_complete'
        | 'password'
        | 'tel'
        | 'date'
        | 'phone_number'
        | 'time'
        | 'switch'
        | 'rich_editor'
        | 'date_time'
        | 'auto_complete_solo'
        | 'multi_item_with_quantity'
        | 'location'
        | 'checkbox'
        | 'email'
        | 'link'
        | 'large_checkbox'
        | 'select'
        | 'file_upload'
        | 'radio'
        | 'file_drop_zone'
        | 'color_picker'
        | 'textArea';
    readonly label?: string;
    readonly show_paper?: boolean;
    readonly maxChar?: number;
    readonly views?: any[];
    readonly minDate?: Date | Moment;
    readonly dateMomentFormat?: string;
    readonly maxDate?: Date;
    readonly file_types?: string[];
    readonly rows?: number;
    readonly minDateRef?: string;
    readonly description?: React.ReactNode;
    readonly placeholder?: string;
    readonly asyncAutoCompleteProps?: AsyncAutocompleteProps;
    // readonly multiItemQuantityProps?: SettingQuantityProviderProps;
    readonly selectOptions?: SelectOption[];
    readonly radioOptions?: RadioOption[];
    readonly switchOption?: RadioOption;
    readonly variant?: 'standard' | 'outlined' | 'filled' | undefined;
    readonly startIcon?: React.ReactElement<SvgIconProps<"svg", {}>, string | React.JSXElementConstructor<any>> | undefined;
    readonly classname?: string;
    readonly orientation?: 'row' | 'column';
    readonly inputProps?: InputTextProps;
    readonly muiInputProps?: any;
    readonly containerProps?: GridProps;
    readonly changeState?: Function;
    readonly autoFocus?: boolean;
    readonly setUrl?: Function;
    readonly imgSrc?: string;
    readonly fullWidth?: boolean;
    readonly autocompleteOptions?: MultiSelectOption[];
    readonly setAutocompleteSelected?: (value: MultiSelectOption) => void;
    readonly autocompleteMultiple?: boolean;
    readonly colorPickerMultiple?: boolean;
    readonly freeSolo?: boolean;
    readonly groupBy?: ((option: any) => string) | undefined;
}

export interface UseFormBuilderProps {
    readonly fields: FormInput[];
    formRef?: React.RefObject<HTMLFormElement>;
    readonly validations: Object;
    readonly initialValues?: Object;
    readonly formProps?: Object;
    color?: 'light' | 'dark';
    initialTouched?: FormikTouched<FormikValues>;
    readonly formClasses?: FormClasses;
    readonly onSubmit: (values: Object, formikHelpers: FormikHelpers<any>) => any;
    readonly renderSubmit?: (
        isSubmitting: boolean,
        errors: FormikErrors<Object>,
        touched: FormikTouched<Object>,
        setFieldTouched: Function,
        handleSubmit: Function,
        values: Object,
    ) => any;
}

export const get_select_styles = (color: string) => ({
    '& .MuiSelect-outlined': {
        padding: '12px 19px',
    },
    '& .MuiInputLabel-formControl': {
        color: color !== 'dark' ? '#ffffff' : '#B8B8B8',
        transform: 'translate(14px,12px) scale(1)',
        fontSize: 14,
        // opacity: 1
    },
    '& legend span': {
        fontSize: 11
    },
    '& .MuiInputLabel-formControl.Mui-focused': {
        color: color !== 'dark' ? '#ffffff' : 'primary.main',
        transform: 'translate(16px, -8px) scale(.75)',
        fontSize: 14,
        opacity: 1,
    },
    '& .MuiInputLabel-formControl.MuiFormLabel-filled': {
        transform: 'translate(16px, -8px) scale(.75)',
    },
})

const FormBuilder = React.forwardRef<HTMLFormElement, UseFormBuilderProps>(
    (props, formRef) => {
        const {
            // formRef,
            initialValues,
            initialTouched = {},
            validations = {},
            formProps = {},
            formClasses = {},
            fields = [],
            color = 'light',
            onSubmit = () => {
            },
            renderSubmit
        } = props;
        // const classes = useStyles();
        const {t, i18n} = useTranslation();

        return (
            <Formik
                initialTouched={initialTouched}
                initialValues={initialValues || {}}
                enableReinitialize={true}
                onSubmit={(values, actions,) => {
                    onSubmit(values, actions);
                }}
                validationSchema={Yup.object().shape(validations as any)}
                render={({
                             values,
                             handleBlur,
                             handleChange,
                             setFieldValue,
                             errors,
                             setErrors,
                             touched,
                             setFieldTouched,
                             isSubmitting,
                             handleSubmit
                         }) => {
                    return (
                        <Form
                            ref={formRef}
                            className={clsx(styles.root, formClasses.root, {
                                ['white_input']: color == 'light'
                            })}
                            {...formProps}>
                            <Grid container columnSpacing={2} component={'div'} alignItems={'stretch'}>
                                {fields
                                    .filter((field) => field)
                                    .map(({type = 'text', ...field}) => (
                                        <Grid
                                            item
                                            key={field.name}
                                            style={{
                                                display: field?.inputProps?.hidden === true ? 'none' : '',
                                                ...(field.containerProps?.style || {})
                                            }}
                                            {...(field.containerProps || {})}
                                            component={'div'}>
                                            {['checkbox'].indexOf(type) !== -1 ? (
                                                <ErrorHandler>
                                                    <Box mb={2}>
                                                        <FormControlLabel
                                                            labelPlacement={'top'}
                                                            control={
                                                                <RadioGroup row={field?.orientation !== 'column'}>
                                                                    {(field.radioOptions || []).map(
                                                                        (radioOption) => (<Box component={'div'} sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'flex-start',
                                                                                flexDirection: 'column'
                                                                            }}>
                                                                                <FormControlLabel
                                                                                    value={radioOption.value}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={
                                                                                                (((values as any)[field.name] ||
                                                                                                    []) as RadioOption[]).findIndex(
                                                                                                    (i) =>
                                                                                                        i.value === radioOption.value
                                                                                                ) !== -1
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                e.persist();
                                                                                                let initialsValues = ((values as any)[
                                                                                                    field.name
                                                                                                    ] || []) as RadioOption[];
                                                                                                if (
                                                                                                    initialsValues.findIndex(
                                                                                                        (i) =>
                                                                                                            i.value === radioOption.value
                                                                                                    ) !== -1
                                                                                                )
                                                                                                    initialsValues = initialsValues.filter(
                                                                                                        (i) =>
                                                                                                            i.value !== radioOption.value
                                                                                                    );
                                                                                                else
                                                                                                    initialsValues.push(radioOption);
                                                                                                setFieldValue(
                                                                                                    field.name,
                                                                                                    initialsValues
                                                                                                );
                                                                                            }}
                                                                                            name={field.name}
                                                                                            color="primary"
                                                                                            value={(values as any)[field.name]}
                                                                                        />
                                                                                    }
                                                                                    label={t(`${radioOption.title}`)}
                                                                                />
                                                                                {radioOption?.subtitle &&
                                                                                    <Typography component={'div'}
                                                                                                variant={'caption'}
                                                                                                color={'textSecondary'}
                                                                                                sx={{ml: 2}}>
                                                                                        {radioOption.subtitle}
                                                                                    </Typography>}
                                                                            </Box>
                                                                        )
                                                                    )}
                                                                </RadioGroup>
                                                            }
                                                            label={!!field.label && `${t(field.label)}`}
                                                            //{...(field.inputProps || {})}
                                                        />
                                                        {(touched as any)[field.name] &&
                                                            (errors as any)[field.name] && (
                                                                <Box mt={1}>
                                                                    <FormHelperText error>
                                                                        {(errors as any)[field.name]}
                                                                    </FormHelperText>
                                                                </Box>
                                                            )}
                                                    </Box>
                                                </ErrorHandler>
                                            ) : ['date', 'time', 'date_time'].indexOf(type) !== -1 ? (
                                                <Box mb={2} component={'div'}>
                                                    <InputDate
                                                        minDate={
                                                            typeof field.minDate !== 'undefined'
                                                                ? field.minDate
                                                                : typeof field.minDateRef !== 'undefined'
                                                                    ? (values as any)[field.minDateRef]
                                                                    : undefined
                                                        }
                                                        maxDate={field.maxDate}
                                                        inputColor={color}
                                                        onBlur={handleBlur}
                                                        views={field.views || []}
                                                        dateMomentFormat={
                                                            field.dateMomentFormat ||
                                                            (i18n.language === 'fr'
                                                                ? 'DD/MM/YYYY'
                                                                : 'YYYY-MM-DD')
                                                        }
                                                        onDateChange={(date) => {
                                                            setFieldValue(field.name, date);
                                                        }
                                                        }
                                                        error={Boolean(
                                                            (touched as any)[field.name] &&
                                                            (errors as any)[field.name]
                                                        )}
                                                        fullWidth={field.fullWidth || false}
                                                        helperText={
                                                            (touched as any)[field.name] &&
                                                            (errors as any)[field.name]
                                                        }
                                                        type={type}
                                                        label={field.label}
                                                        name={field.name}
                                                        value={(values as any)[field.name]}
                                                        {...(field.inputProps || {})}
                                                        variant={'standard'}
                                                    />
                                                    {/*{(touched as any)[field.name] &&
                            (errors as any)[field.name] &&
                            (errors as any)[field.name] !== '' && (
                              <Box mt={1}>
                                <FormHelperText error>
                                  {(errors as any)[field.name]}
                                </FormHelperText>
                              </Box>
                            )}*/}
                                                </Box>
                                            ) : ['phone_number'].indexOf(type) !== -1 ? (
                                                <Box mt={2} component={'div'} sx={{
                                                    '& svg': {
                                                        height: '24px'
                                                    }
                                                }}>
                                                    <MuiPhoneInput
                                                        defaultCountry='fr'
                                                        error={Boolean(
                                                            (touched as any)[field.name] &&
                                                            (errors as any)[field.name]
                                                        )}
                                                        fullWidth={field.fullWidth || false}
                                                        // startIcon={field.startIcon}
                                                        variant={'outlined'}
                                                        placeholder={field.placeholder}
                                                        autoFocus={field.autoFocus || false}
                                                        helperText={
                                                            (touched as any)[field.name] &&
                                                            (errors as any)[field.name]
                                                        }
                                                        label={field.label}
                                                        name={field.name}
                                                        onBlur={handleBlur}
                                                        onChange={(value) => setFieldValue(field.name, value)}
                                                        type={type}
                                                        value={(values as any)[field.name]}
                                                        // {...(field.inputProps || {})}

                                                    />
                                                </Box>
                                            ) : ['file_upload'].indexOf(type) !== -1 ? (
                                                <Box my={2} component={'div'}>
                                                    <FileUploader
                                                        file_types={field.file_types}
                                                        data={(values as any)[field.name]}
                                                        label={field.label}
                                                        error={(touched as any)[field.name] &&
                                                            (errors as any)[field.name]}
                                                        onChange={(v) => {
                                                            setFieldValue(field.name, v);
                                                        }}
                                                    />
                                                </Box>
                                            ) : ['switch'].indexOf(type) !== -1 ? (
                                                <Box mb={2} component={'div'}>
                                                    <FormControlLabel
                                                        // value={field.value ?? false}
                                                        control={
                                                            <Switch
                                                                checked={typeof (values as any)[field.name] === 'undefined' ? false : (values as any)[field.name]}
                                                                onChange={(e) => {
                                                                    e.persist();
                                                                    setFieldValue(field.name, e.target.checked);
                                                                }}
                                                                name={field.name}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={t(`${field.switchOption?.title || ''}`)}
                                                    />
                                                    {field.switchOption?.subtitle &&
                                                        <Typography component={'div'} variant={'caption'}
                                                                    color={'textSecondary'} sx={{ml: 2}}>
                                                            {field.switchOption.subtitle}
                                                        </Typography>}
                                                    {(touched as any)[field.name] &&
                                                        (errors as any)[field.name] && (
                                                            <Box mt={1}>
                                                                <FormHelperText error>
                                                                    {(errors as any)[field.name]}
                                                                </FormHelperText>
                                                            </Box>
                                                        )}
                                                </Box>
                                            ) : ['auto_complete_solo'].indexOf(type) !== -1 ? (
                                                <ErrorHandler>
                                                    <Box component={'div'} pt={2}>
                                                        <AutocompleteMultipleSolo
                                                            label={field.label}
                                                            disable={field.inputProps?.disabled ?? false}
                                                            onChange={(content) => {
                                                                setFieldValue(field.name, content)
                                                            }}
                                                            value={(values as any)[field.name] || [] as string[]}
                                                            error={(errors as any)[field.name] && (errors as any)[field.name]}
                                                            helperText={(errors as any)[field.name] && (errors as any)[field.name]}
                                                        />
                                                    </Box>
                                                </ErrorHandler>
                                            ) : ['async_auto_complete'].indexOf(type) !== -1 &&
                                            field.asyncAutoCompleteProps ? (
                                                <ErrorHandler>
                                                    <Box mb={2}>
                                                        <AsyncAutocomplete
                                                            {...field.asyncAutoCompleteProps}
                                                            color={color}
                                                            disabled={field.inputProps?.disabled ?? false}
                                                            value={(values as any)[field.name]}
                                                            onChange={(value) =>
                                                                setFieldValue(field.name, value)
                                                            }
                                                        />
                                                    </Box>
                                                </ErrorHandler>
                                            ) : ['color_picker'].indexOf(type) !== -1 ? (
                                                <ErrorHandler>
                                                    <Box component={'div'} pt={2}>
                                                        <ColorPicker
                                                            value={(values as any)[field.name]}
                                                            onChange={(value) => {
                                                                setFieldValue(field.name, value)
                                                            }}
                                                            label={field.label}
                                                            multiple={field.colorPickerMultiple}
                                                        />
                                                    </Box>
                                                </ErrorHandler>
                                            ) : ['textArea', 'text', 'number', 'email', 'password'].indexOf(
                                                type
                                            ) !== -1 ? (
                                                    <InputText
                                                        sx={get_input_sx(color)}
                                                        error={Boolean(
                                                            (touched as any)[field.name] &&
                                                            (errors as any)[field.name]
                                                        )}
                                                        fullWidth={field.fullWidth || false}
                                                        // startIcon={field.startIcon}
                                                        variant={'outlined'}
                                                        placeholder={field.placeholder}
                                                        autoFocus={field.autoFocus || false}
                                                        helperText={
                                                            (touched as any)[field.name] &&
                                                            (errors as any)[field.name]
                                                        }
                                                        label={field.label}
                                                        name={field.name}
                                                        onBlur={handleBlur}
                                                        rows={field.rows ?? 1}
                                                        multiline={type === "textArea"}
                                                        onChange={handleChange}
                                                        type={type}
                                                        InputProps={field.muiInputProps}
                                                        value={(values as any)[field.name]}
                                                        {...(field.inputProps || {})}
                                                    />

                                                )
                                                : ['typography'].indexOf(type) !== -1 ? (
                                                    <Typography variant={'body2'}>
                                                        {field?.description}
                                                    </Typography>
                                                ) : ['radio'].indexOf(type) !== -1 ? (
                                                    <ErrorHandler>
                                                        <Box mb={2}>
                                                            <FormControlLabel
                                                                labelPlacement={'top'}
                                                                control={
                                                                    <RadioGroup
                                                                        row
                                                                        value={(values as any)[field.name]}>
                                                                        {(field.radioOptions || []).map(
                                                                            (radioOption) => (
                                                                                <FormControlLabel
                                                                                    value={radioOption.value}
                                                                                    control={
                                                                                        <Radio
                                                                                            checked={
                                                                                                (values as any)[field.name] ===
                                                                                                radioOption.value
                                                                                            }
                                                                                            onChange={(event, value) => {
                                                                                                setFieldValue(
                                                                                                    field.name,
                                                                                                    radioOption.value
                                                                                                );
                                                                                                field.changeState &&
                                                                                                field.changeState(
                                                                                                    radioOption.value
                                                                                                );
                                                                                            }}
                                                                                            color={'primary'}
                                                                                        />
                                                                                    }
                                                                                    label={t(`${radioOption.title}`)}
                                                                                />
                                                                            )
                                                                        )}
                                                                    </RadioGroup>
                                                                }
                                                                label={!!field.label && t(`${field.label}`)}
                                                                //{...(field.inputProps || {})}
                                                            />
                                                            {(touched as any)[field.name] &&
                                                                (errors as any)[field.name] && (
                                                                    <Box mt={1}>
                                                                        <FormHelperText error>
                                                                            {(errors as any)[field.name]}
                                                                        </FormHelperText>
                                                                    </Box>
                                                                )}
                                                        </Box>
                                                    </ErrorHandler>
                                                ) : ['tel'].indexOf(type) !== -1 ? (
                                                    <MuiTelInput
                                                        value={(values as any)[field.name]}
                                                        variant ='outlined'
                                                        onChange={(e,value) =>{
                                                            handleChange
                                                        }}
                                                        InputProps={field.muiInputProps}
                                                        disabled={field?.inputProps?.disabled ??  false}
                                                        fullWidth={field.fullWidth || false}
                                                        error={Boolean(
                                                            (touched as any)[field.name] &&
                                                            (errors as any)[field.name]
                                                        )}
                                                    />
                                                ) : ['select'].indexOf(type) !== -1 ? (
                                                    <ErrorHandler>
                                                        <Box mb={2} sx={get_select_styles(color)}>
                                                            <FormControl
                                                                disabled={field?.inputProps?.disabled || undefined}
                                                                error={Boolean(
                                                                    (touched as any)[field.name] &&
                                                                    (errors as any)[field.name]
                                                                )}
                                                                onBlur={() => handleBlur(field.name)}
                                                                fullWidth={field.fullWidth || false}
                                                                // autoFocus={field.autoFocus || false}
                                                                variant={field.variant || 'outlined'}
                                                                //    {...(field.inputProps || {})}
                                                            >
                                                                <InputLabel id={field.name}>
                                                                    {field.label}
                                                                </InputLabel>
                                                                <Select
                                                                    variant={'outlined'}
                                                                    labelId={field.name}
                                                                    id={field.name}
                                                                    placeholder={field.placeholder}
                                                                    name={field.name}
                                                                    value={(values as any)[field.name]}
                                                                    onChange={handleChange}
                                                                    label={field.label}>
                                                                    {(field.selectOptions || []).map(
                                                                        (selectOption) => (
                                                                            <MenuItem
                                                                                key={selectOption.value}
                                                                                value={selectOption.value}>
                                                                                {selectOption.title}
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                            {(touched as any)[field.name] &&
                                                                (errors as any)[field.name] && (
                                                                    <Box mt={1}>
                                                                        <FormHelperText error>
                                                                            {(errors as any)[field.name]}
                                                                        </FormHelperText>
                                                                    </Box>
                                                                )}
                                                        </Box>
                                                    </ErrorHandler>
                                                ) : ['auto_complete'].indexOf(type) !== -1 ? (
                                                        <ErrorHandler>
                                                            <Box component={'div'} mb={2} sx={{}}>
                                                                <FormControl
                                                                    margin={field?.inputProps?.margin}
                                                                    onBlur={handleBlur}
                                                                    fullWidth={field.fullWidth || false}
                                                                    variant={field.variant || 'outlined'}>
                                                                    <Autocomplete
                                                                        noOptionsText={'Aucun choix disponible'}
                                                                        loadingText={'Chargement'}
                                                                        id={field.name}
                                                                        groupBy={field.groupBy}
                                                                        freeSolo={field.freeSolo}
                                                                        sx={{
                                                                            height: '45px',
                                                                            '& .MuiOutlinedInput-root': {
                                                                            height: '45px',
                                                                            },
                                                                            '& .MuiInputBase-input': {
                                                                            height: '45px',
                                                                            padding: '12px',
                                                                            },
                                                                            '& svg': {
                                                                                fill: '#fff'
                                                                            }
                                                                        }}
                                                                        renderGroup={(data) => {
                                                                            return field.groupBy ?
                                                                                <Box key={data.key}>
                                                                                    <Typography variant={'subtitle1'}
                                                                                                color={"secondary"}>
                                                                                        {data.group}
                                                                                    </Typography>
                                                                                    <Typography variant={'subtitle1'}>
                                                                                        {data.children}
                                                                                    </Typography>

                                                                                </Box> : <div/>
                                                                        }}
                                                                        multiple={field.autocompleteMultiple}
                                                                        filterSelectedOptions={true}
                                                                        getOptionDisabled={
                                                                            field.autocompleteMultiple
                                                                                ? (option) =>
                                                                                    (
                                                                                        (values as any)[field.name] || []
                                                                                    ).findIndex(
                                                                                        (i: MultiSelectOption) =>
                                                                                            i.id === option.id
                                                                                    ) !== -1
                                                                                : (option) =>
                                                                                    option.id === (values as any)[field.name]
                                                                        }
                                                                        getOptionLabel={(option) => {
                                                                            if (
                                                                                typeof option === 'number' ||
                                                                                typeof option === 'string'
                                                                            ) {
                                                                                const selected = (
                                                                                    field.autocompleteOptions || []
                                                                                ).find((i: any) => i.id === option);
                                                                                return selected ? selected.label : '';
                                                                            } else {
                                                                                return option.label || '';
                                                                            }
                                                                        }}
                                                                        options={field.autocompleteOptions || []}
                                                                        // renderOption={(option: any ) => {
                                                                        //   console.log('renderOption', option);
                                                                        //   return (
                                                                        //       <Typography variant={'body1'}>
                                                                        //         {option?.label}
                                                                        //       </Typography>
                                                                        //   )
                                                                        // }}
                                                                        disabled={field?.inputProps?.disabled}
                                                                        value={(values as any)[field.name]}
                                                                        onChange={(e: any, value: any) => {
                                                                            if (value) {
                                                                                setFieldValue(
                                                                                    field.name,
                                                                                    field.autocompleteMultiple
                                                                                        ? value
                                                                                        : value.id
                                                                                );
                                                                            }
                                                                            if (field.setAutocompleteSelected)
                                                                                field.setAutocompleteSelected(value);
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                label={field.label}
                                                                                helperText={
                                                                                    (touched as any)[field.name] &&
                                                                                    (errors as any)[field.name]
                                                                                }
                                                                                error={Boolean(
                                                                                    (touched as any)[field.name] &&
                                                                                    (errors as any)[field.name]
                                                                                )}
                                                                                {...params}
                                                                                autoFocus={field.autoFocus || false}
                                                                                variant={field.variant || 'outlined'}
                                                                                sx={{
                                                                                    '& .MuiAutocomplete-input': {
                                                                                        height: '45px',
                                                                                        marginTop: '-15px',
                                                                                        fontSize: '15px'
                                                                                    },
                                                                                    '& .MuiInputLabel-root': {
                                                                                        color: '#919FBB',
                                                                                        fontSize: '15px',
                                                                                        marginTop: '-3px'
                                                                                    },
                                                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                                                        color: '#919FBB',
                                                                                    },
                                                                                    '& .MuiFormLabel-root.Mui-error': {
                                                                                        color: '#D22630',
                                                                                    },
                                                                                    '& .MuiInputBase-input::placeholder': {
                                                                                      color: '#919FBB',
                                                                                      opacity: 1,
                                                                                    },
                                                                                  }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                            </Box>
                                                        </ErrorHandler>
                                                    )
                                                    //
                                                    //             : ['file_drop_zone'].indexOf(type) !== -1 ? (
                                                    //   <ErrorHandler>
                                                    //     <Box
                                                    //       pb={2}
                                                    //       className={classes.dropZone}
                                                    //       component={'div'}>
                                                    //       <FilesDropZone
                                                    //         setUrl={(resource: FileResource) =>
                                                    //           field.setUrl && field.setUrl(resource)
                                                    //         }
                                                    //         name={field.name}
                                                    //         field={field}
                                                    //         defaultImgSrc={field.imgSrc && field.imgSrc}
                                                    //         onChange={(content) =>
                                                    //           setFieldValue(field.name, content)
                                                    //         }
                                                    //       />
                                                    //       {(touched as any)[field.name] &&
                                                    //         (errors as any)[field.name] && (
                                                    //           <Box mt={1}>
                                                    //             <FormHelperText error>
                                                    //               {(errors as any)[field.name]}
                                                    //             </FormHelperText>
                                                    //           </Box>
                                                    //         )}
                                                    //     </Box>
                                                    //   </ErrorHandler>
                                                    // )

                                                    : ['link'].indexOf(type) !== -1 ? (
                                                        <ErrorHandler>
                                                            <Box component={'div'} mb={2}>
                                                                <Typography variant="h5">
                                                                    {' '}
                                                                    <Link underline={'always'}>
                                                                        {field.description}
                                                                    </Link>
                                                                </Typography>
                                                            </Box>
                                                        </ErrorHandler>
                                                    ) : (
                                                        <div/>
                                                    )}
                                        </Grid>
                                    ))}
                                {renderSubmit &&
                                    renderSubmit(
                                        isSubmitting,
                                        errors,
                                        touched,
                                        setFieldTouched,
                                        handleSubmit,
                                        values,
                                    )}
                            </Grid>
                        </Form>
                    )
                }}
            />
        );
    }
);

export default FormBuilder;