export default {
  "translation": {
    "shared.french": "English",
    "shared.english": "Deutsch",
    "login_page_welcome": "Welcome!",
    "login_page_subtitle": "Sign in to continue.",
    "login_form_email": "Enter email",
    "login_form_password": "Enter password",
    "login_page_action": "Sign in",
    "login_form_require": "Required field",
    "login_page_title": "Login",
    "welcome_page_title": "Welcome to the PKF Mandantenportal",
    "welcome_page_subtitle": "Please select your office on the right",
    "login_page_forgot_password": "Forgot your password?",
    "field_require": "Required field",
    "confirm_code_title": "Account confirmation",
    "confirm_code_subtitle": "We have sent a code to your mailbox. Check your mailbox.",
    "confirm_code_caption": "Please enter the code you received by email",
    "confirm_coded_label": "Enter verification code",
    "confirm_code_action": "Confirm",
    "confirm_code_retry": "Resend email?",
    "welcome_page_continue": "To login",
    "reset_page_title": "Reset Password",
    "reset_page_description": "Enter your email address and we will send you a code to reset your password.",
    "reset_page_action": "Send code",
    "reset_page_remember_title": "I remember my ID",
    "reset_page_description_1": "We have sent you a code to reset your password",
    "reset_page_action_1": "Confirm code",
    "reset_password_another": "Try a different address",
    "reset_password_resend": "Resend code",
    "reset_page_title_2": "Create a new password",
    "enter_password_receive_byt_mail": "Please enter the code you received by email",
    "project_title_button_1": "Projects",
    "project_title_button_2": "Manage",
    "groups_title_users": "Users",
    "create_new_group": "New group",
    "create_new_group_send": "Create a new group",
    "create_new_group_placeholder_name": "Group name",
    "delete_groupe_message_warning": "Are you sure you want to delete this group? This action cannot be undone and you will not be able to recover any data.",
    "delete_site_message_warning": "Are you sure you want to delete this office? This action cannot be undone and you will not be able to recover any data.",
    "delete_cancel": "Cancel",
    "delete_ok": "Yes",
    "delete_title_confirm": "Delete",
    "add_new_site": "Add a new user",
    "create_new_site": "Add office",
    "new_site": "New office",
    "location_site": "Location",
    "site_name": "Office name",
    "update_new_site": "Update new office",
    "create_new_user_action": "Add user",
    "create_att": "Created on",
    "edit_att": "Last edited:",
    "create_by": "created by:",
    "by": "By",
    "upload_blind": "Upload image",
    "create_new_group_placeholder_ceo_name": "CEO name",
    "previous": "Previous",
    "next": "Next",
    "update_new_group": "Update group",
    "show_all_site": "Show all locations...",
    "shared.internet_connexion_error": "An unknown error occurred. Please try again later.",
    "login_form_password_new": "New password",
    "login_form_password_new_confirmation": "Confirm password",
    "edit_site": "Edit office",
    "delete_site": "Delete office",
    "add_new_user": "Add PKF user",
    "translate_key_existing_user": "Select user",
    "admin_space": "Admin area",
    "group_name": "Group",
    "group_remember": "Remember to add a page to your group",
    "reset_password_dont_receive": "Didn't receive an email? Check your SPAM boxes or",
    "top_bar_profile_tooltip": "Profile",
    "top_bar_notif_tooltip": "Notification",
    "search_new_user": "Search user",
    "create_new_user": "New user",
    "update_new_user_action": "Update user",
    "create_new_user_firstname": "First name",
    "create_new_user_lastname": "Last name",
    "create_new_user_email": "Email",
    "create_new_user_role": "Select a role for this user",
    "create_new_user_password": "Create new password",
    "create_new_user_status": "Define a status for this user",
    "create_new_user_status_UNVALIDATED": "Unvalidated",
    "create_new_user_status_ACTIVED": "Active",
    "create_new_user_status_UNACTIVED": "Inactive",
    "create_new_user_status_DELETED": "Deleted",
    "field_require_email": "Please enter a valid email",
    "update_new_user": "Update user",
    "delete_new_user": "Delete user",
    "page_users_title": "PKF employees",
    "page_clients_title": "PKF clients overview",
    "page_users_filter": "Sort by:",
    "page_users_name": "Name",
    "page_users_project": "Projects",
    "page_users_client": "Client",
    "page_users_status": "Status",
    "page_users_email": "Email",
    "page_users_role": "Role",
    "page_users_add": "Added on",
    "page_users_edit": "Edit",
    "page_users_sites": "Office",
    "page_users_activity": "Last activity",
    "page_users_add_by": "Created by: ",
    "page_users_add_by_document": "Created by ",
    "page_users_actions": "Actions",
    "page_users_date": "Created on",
    "group_add_success": "Group created successfully",
    "site_add_success": "Office created",
    "site_update_success": "Office updated",
    "clients_for_site": "Clients for this office",
    "projet_for_site": "Projects for this office",
    "location_users": "Select a location where the user will be based",
    "new_client": "New client",
    "search_client": "Search client",
    "add_new_client_title": "Add a new client",
    "title_add_datev_mandant": "Enter the customer name, we will search for it in the Datev database to assist you with the import.",
    "translate_key_add_imported_client_title": "Import client from Datev",
    "button_add_client": "Add client",
    "update_client": "Update client",
    "official_name": "Official name",
    "interner_name": "Internal name",
    "client_title_page": "Clients",
    "edit_client": "Edit this client",
    "delete_client": "Delete client",
    "client_create_success": "Client successfully created",
    "client_update_success": "Client successfully updated",
    "client_show_details": "Show details",
    "delete_client_message_warning": "Are you sure you want to delete this client? This action cannot be undone and you will not be able to recover any data.",
    "client_detail_detail": "Show details",
    "page_users_modify": "Last modified",
    "users_filter_by_date_added": "Date added",
    "users_filter_by_last_activity": "Last activity",
    "users_filter_by_firstname": "First name",
    "users_filter_by_lastname": "Last name",
    "users_filter_by_email": "Email",
    "users_filter_by_no_role": "Have no role",
    "users_filter_by_unactive": "Inactive",
    "client_detail_added": "Added:",
    "client_detail_added_by": "Added by:",
    "client_detail_last_edit": "Last edit:",
    "client_detail_last_edit_by": "Edited by:",
    "manage_user_action": "Manage",
    "manage_user_new_project": "Add new project",
    "client_detail_users": "These users can see {{enterprise}}",
    "client_detail_users_locked": "These users cannot see {{enterprise}}",
    "users_updated_success": "User list successfully updated",
    "client_detail_users_dialog_input": "Add new user",
    "auto_complete_empty_user": "No user found",
    "customers_filter_by_date_added": "Date added",
    "clients_filter_by_last_activity": "Last change",
    "clients_filter_by_date_added": "Date added",
    "clients_filter_by_internalname": "Internal name",
    "clients_filter_by_official_name": "Official name",
    "customers_filter_by_email": "Email",
    "create_new_user_site": "Select a site for this user",
    "create_new_client_site": "Select a site for this client",
    "add_user_description": "This new user will be automatically added to the {{website}} site",
    "user_created_success": "User account successfully created",
    "user_update_success": "User account successfully updated",
    "user_delete_success": "User account successfully deleted",
    "user_delete_confirm": "Are you sure you want to delete this user?",
    "delete_client_user_message_warning": "Are you sure you want to remove this user?",
    "home_search_project_title": "Search client",
    "home_search_task": "Search...",
    "detail_home_title": "My projects",
    "detail_home_list": "Create task list",
    "detail_home_template": "Templates",
    "detail_home_status": "Status",
    "detail_home_comments": "Comments",
    "detail_home_activities": "Activities",
    "detail_home_status_open": "Open",
    "detail_home_status_accepted": "Done",
    "detail_home_status_week": "Due this week",
    "detail_home_status_late": "Late",
    "detail_home_status_rejected": "Rejected",
    "detail_home_user": "User",
    "detail_home_task": "Hidden task",
    "activity_tabs_title": "Activities",
    "delete_document_success": "{{document}} successfully deleted",
    "login_form_role": "Role",
    "title_new_site": "Site",
    "clients_filter_by_email": "Email",
    "create_task_date": "Created on",
    "join_on_title": "Join",
    "project_on": "Projects on",
    "project_done": "Project completed",
    "tasks_on": "Tasks on",
    "profil_title_user": "Profile User",
    "profile_change_title": "Change profile picture",
    "show_picture": "View picture",
    "change_picture_profile": "Change picture",
    "delete_picture_user": "Delete picture",
    "file_updated_by": "Updated by",
    "document_list_create_success": "Document successfully added",
    "document_list_delete_success": "Document successfully deleted",
    "confirm_loadtemplate": "Load template",
    "load_template_action": "Yes! Load",
    "load_template_success": "Template successfully loaded",
    "confirm_load_template_or_group": "Are you sure you want to load {{template}} selected template?",
    "document_action_hide": "Hide",
    "document_action_delete": "Delete",
    "document_action_awaiting": "Document opened",
    "document_action_accepted": "PKF completed",
    "document_action_rejected": "PKF rejected",
    "document_confirm_delete": "Are you sure you want to delete this document? This action cannot be undone and you won't be able to recover any data",
    "document_confirm_hide": "Are you sure you want to hide this document?",
    "document_confirm_change_status": "Are you sure you want to change the status of this document?",
    "document_confirm_change": "Confirmation",
    "save_task_update": "Save",
    "client_detail_users_dialog_title": "Invite added client users to project",
    "client_detail_users_dialog_action": "Confirm",
    "home_task_progress": "Tasks in progress",
    "home_task_outdate": "Overdue tasks",
    "home_task_list_title": "Ongoing tasks",
    "home_task_project_detail": "More details",
    "home_mobile_dialog_title": "Task calendar",
    "home_task_project_message": "{{length}} Messages",
    "home_task_project_task": "{{length}} Pending tasks",
    "home_task_done": "Task completed",
    "tasks_done": "Tasks completed",
    "home_task_add": "Add task",
    "button_detail_open": "Open",
    "select_status": "Select status",
    "select_option_deadline": "No deadline yet -",
    "upload_file_placeholder": "Upload file",
    "browser_file": "Browse file",
    "type_flie_upload": "All types of documents (Word, PDF, Excel, etc...).",
    "max_size_upload": "Maximum size per file 250MB",
    "no_upload_yet": "No document uploaded yet",
    "comment_tabs_title": "Comments",
    "activitie_tabs_title": "Activities",
    "project_dialog_title": "Add a new project",
    "project_dialog_update_title": "Update project",
    "project_dialog_add_action": "Add project",
    "project_dialog_desc": "Description",
    "project_dialog_deadline": "Deadline",
    "email_resend_successfully": "Code successfully sent again by email",
    "project_created_success": "Project successfully created",
    "project_update_success": "Project successfully updated",
    "not_project_available": "No project available for this client",
    "task_role_owner": "(Project Owner)",
    "task_role_collaborator": "(Collaborator)",
    "task_role_mandant": "Client",
    "login_super_admin": "Admin Login",
    "email_form_invalid": "Please enter a valid email address",
    "login_super_admin_description": "Login as admin",
    "task_users_all": "Show all participants",
    "task_list_create_success": "Task list successfully created",
    "task_list_update_success": "Task list successfully updated",
    "task_list_delete_success": "Task list successfully deleted",
    "task_create_success": "Task successfully created",
    "task_update_success": "Task successfully updated",
    "task_delete_success": "Task successfully deleted",
    "status_update_success": "Status successfully updated",
    "delete_task_update_success": "Task list and/or task successfully deleted",
    "grant_task_update_success": "List of authorized users successfully updated",
    "all_template_list": "All templates",
    "my_template_list": "My templates",
    "stardard_template_list": "Standard templates",
    "edit_template_menu": "Edit template",
    "delete_template_menu": "Delete template",
    "load_template_menu": "Load template",
    "delete_message_template": "Are you sure you want to delete this template? This action cannot be undone and you will not be able to recover any data.",
    "site_delete_success": "Location deleted successfully",
    "group_update_success": "Group update successful",
    "create_template_button": "Create new empty template",
    "edit_template_button": "Edit template",
    "manager_template_button": "Manage / load templates",
    "create_new_template_title": "Create new template",
    "update_new_template_title": "Update new template",
    "delete_template_success": "Template successfully deleted",
    "delete_task_success": "Task successfully deleted",
    "update_template_success": "Template successfully updated ",
    "update_task_success": "Task successfully updated: ",
    "create_template_success": "Template successfully created",
    "create_task_success": "Task successfully created",
    "add_task_list": "Add task list",
    "add_placeholder_task_list": "Add new task list...",
    "home_task_project": "Project",
    "home_task_project_document": "{{length}} documents",
    "user_page_title": "Users",
    "update_user_menu": "Update this user",
    "delete_user_menu": "Delete this user",
    "view_user_tasks": "View assigned tasks",
    "auto_complete_loading": "Loading...",
    "auto_complete_keyword_empty": "No result found",
    "auto_complete_empty_health_personal": "No page found",
    "auto_complete_empty": "Enter a keyword",
"home_page_title": "Homepage",
    "add_task": "Add task",
    "update_template_task_group": "Update template task group",
    "update_template_group": "Update template task",
    "delete_message_task_group_template": "Are you sure you want to delete the task group name: \" {{task}}\" ? This action cannot be undone and you will not be able to recover any data.",
    "delete_message_task_template": "Are you sure you want to delete the task name: \" {{task}}\" ? This action cannot be undone and you will not be able to recover any data.",
    "new_user_in_client_site_name": "{{name}} automatically",
    "edit_user_client": "Edit this user",
    "delete_user_client": "Delete this user",
    "users_filter_by_last_date_modified": "Version from",
    "clients_filter_by_date_modified": "Modification date",
    "see_all_projet": "See all projects",
    "see_all_users": "See all users",
    "udpate_task": "Update task",
    "udpate_tasklist": "Update task list",
    "delete_task": "Delete task",
    "confirm_delete_task_or_group": "Are you sure you want to delete the selected task lists and/or tasks?",
    "status_to_text_OPEN": "Open",
    "status_to_text_ACCEPTED": "Done",
    "status_to_text_IN_PROGRESS": "In progress",
    "status_to_text_REJECTED": "Rejected",
    "status_to_text_SUFFERING": "Overdue",
    "status_to_text_DUE_THIS_WEEK": "DUE THIS WEEK",
    "status_to_text_DONE": "Done",
    "document_action_sign": "Sign",
    "document_sign_title": "Sign document",
    "document_request_sign_title": "Request sign",
    "document_request_sign_action": "Sign document",
    "document_request_sign_customer": "Find customer",
    "document_action_sign_user_name": "Username",
    "start_sign": "Start signing",
    "validate_new_user_sign": "Add",
    "save_user_to_sign": "Send request",
    "create_new_task": "Create new task list",
    "internal_name": "Internal name",
    "create_new_task_in": "Create new task in",
    "list_of_delete_group_tasks": "This is the list of groups task you want to delete, you need to select the desired task group to confirm the deletion",
    "edit_task": "Edit task",
    "new_user_in_client": "This new user will be added",
    "update_user_in_client": "This user will be updated",
    "empty_safe_zone_title": "You don't have a safe yet.",
    "empty_safe_zone_action": "Create my safe",
    "create_safe_zone_description": "Define a secret code that you will use to access your safe. You will be asked for this code each time you try to open the safe.",
    "create_safe_zone_code": "Secret code",
    "safe_zone_structure_title": "Manage structure",
    "create_safe_zone_question": "Set your secret question",
    "create_safe_zone_response": "Answer the question",
    "empty_safe_zone_subtitle": "You don't have a safe yet. You can create one now and store your confidential documents in it. Only you will be able to access it.",
    "log_page_search_title": "Search the log by task or document name, etc.",
    "log_page_title": "Logs",
    "test_form_details_profile": "Email",
    "title_management_user_title": "PKF employees",
    "test_form_details_profile_site": "Office",
    "test_form_details_profile_date": "Registered since",
    "set_tab_container": "Set a reminder",
      "set_reminder_title1": "No entries have been made yet",
      "set_reminder_title2": "Do you want to add a reminder for this client?",
      "set_reminder_button_title": "Set reminder",
      "download_document": "Download document",
      "download_sign_document": "Download signed document",
      "new_client_contact": "Contact person",
      "new_client_infos": "Client information",
      "safe_zone_page_title": "Safe zone",
      "safe_zone_structure_settings": "Settings",
      "folder_table_name": "Folder",
      "folder_table_files": "Files",
      "folder_table_date_added": "Date created",
      "folder_table_date_modified": "Last modified",
      "file_table_name": "File name",
      "file_table_size": "Size",
      "file_table_date_added": "Date created",
      "file_table_date_modified": "Last modified",
      "logged_safe_zone_title": "My Safe Zone",
      "logged_safe_zone_subtitle": "Enter your credentials to log in to your Safe Zone",
      "logged_safe_zone_action": "Login",
      "add_document_key": "Add document",
      "update_document_action": "Upload documents",
      "delete_confirmation": "Are you sure you want to delete {{file}}?",
      "activity_lock": "Activity Log",
      "project_status_label": "Project status",
      "project_status_archived": "Archived",
      "project_status_un_ archived": "Unarchived",
      "listing_task": "Task list",
      "warning_safe_zone_title": "Warning",
      "warning_safe_zone_validation": "Okay",
      "warning_safe_zone_message": "The use of the SafeZone is restricted to its owner. Fraudulent use of another person's SafeZone may lead to criminal prosecution.",
      "listing_project": "User's project list",
      "title_button": "Does this task remain unchanged?",
      "title_change_unchanged": "Task remains unchanged",
      "title_change_confirm": "Yes! This task remains unchanged",
      "task_unchange_description": "Are you sure that the task {{task}} is unchanged from last year? This change will be visible to all other users with access to this project",
      "task_not_sure_changed": "I'm not sure",
      "task_unchanged_from_last_year": "Unchanged from last year",
      "forget_safe_zone_code": "Reset my code",
      "forget_safe_zone_question": "Reset my question",
      "hide_project": "Hide",
      "message_hide_task": "Are you sure you want to hide this task",
      "hidden_task_title": "Hidden task",
      "task_mask_success": "Task hidden successfully",
      "task_folder_title": "Hidden tasks",
      "document_menu_title": "Documents",
      "download_title": "Download",
      "document_selected": "Selected documents",
      "new_subgroup_title_create": "Create a new subgroup",
      "apply_to_safe_zone_title": "Apply to safe zone",
      "reapply_to_safe_zone_title": "Re-enter the safe zone",
      "text_on_asked_to_safe_zone": "This is your personal (company) data safe! It allows you to organize your most important documents such as wills, contracts, passwords, etc. Your request has been processed by your PKF! contact person and is in the validation phase.",
      "text_on_reapply_to_safe_zone": "This is your personal (company) data safe! It allows you to organize your most important documents such as wills, contracts, passwords, etc. Your previous request was rejected, to re-activate it, please contact your PKF contact person!",
      "text_on_apply_to_safe_zone": "This is your personal (company) data safe! Here you can organize your most important documents, such as wills, contracts, passwords, etc. To activate it, please contact your PKF contact person!",
      "text_send_button": "Send",
  "accepted_request_button": "Accept request",
  "refused_button": "Refuse",
  "asked_safe_zone": "Application for activation",
  "task_filter": "Task",
  "task_group_filter": "Task group",
  "file_created_by": "Created by {{name}}\nOn {{date}}",
  "add_button_authorization_title": "Add participants",
  "login_with_microsoft_text": "In 1 click with your $microsoft account",
  "azure_approvisionnement": "Azure AD provisioning information",
  "azure_approvisionnement_description": "Azure AD needs the following information to connect to the Mandanten Portal API and synchronize user data",
  "block_azure_action_copy": "Copy",
  "block_azure_doc": "Azure AD provisioning configuration procedures via SCIM",
  "pdf_viewer_default_title": "Document",
  "azure_doc_title": "Procedure for configuring Azure AD provisioning via SCIM",
  "azure_doc_description": "Use the Azure Active Directory portal to create and configure an application for automatic provisioning. In our case, we will perform the configuration with Mandanten Portal. Follow the following steps in order",
  "azure_doc_open": "Open",
  "azure_doc_open_2": "the Azure AD portal",
  "azure_doc_open_3": "and select",
  "azure_doc_open_4": "Enterprise Applications",
  "azure_doc_step_2_a": "Rest assured that",
  "azure_doc_step_2_b": "All applications",
  "azure_doc_step_2_c": "are checked in the field",
  "azure_doc_step_2_d": "Manage",
  "azure_doc_step_2_e": "then click on",
  "azure_doc_step_2_f": "New application",
  "azure_doc_step_3_a": "Now click on",
  "azure_doc_step_3_b": "Create your own application",
  "azure_doc_step_3_c": "A sliding block is displayed. Enter the name of the application and check that the option",
  "azure_doc_step_3_d": "Integrate any other application you don't find in the gallery (Non-gallery)",
  "azure_doc_step_3_e": "be checked. Then click on the button",
  "azure_doc_step_3_f": "Create",
  "azure_doc_step_4_a": "Perfect! Your application is created. Click on",
  "azure_doc_step_4_b": "Provisioning",
  "azure_doc_step_5_a": "Click on",
  "azure_doc_step_5_b": "Start",
  "azure_doc_step_6_a": "Go to Mandanten Portal and copy the content of the fields",
  "azure_doc_step_6_b": "URL",
  "azure_doc_step_6_c": "and",
  "authorization_title": "Permissions overview",
  "azure_doc_step_6_d": "Token uuid",
  "azure_doc_step_8_a": "Paste the content of the field",
  "azure_doc_step_8_b": "in the field",
  "azure_doc_step_8_c": "Tenant URL",
  "azure_doc_step_8_d": "Do the same with ",
  "azure_doc_step_8_e": "Secret Token",
  "azure_doc_step_8_f": "Test the connections with Mandanten Portal by clicking on",
  "azure_doc_step_8_g": "Test the connection",
  "azure_doc_step_8_h": "If all goes well, click on",
  "azure_doc_step_8_i": "Save",
  "azure_doc_step_9_a": "Then click on the name of your application in the breadcrumb trail.",
  "azure_doc_step_10_a": "Then click on",
  "azure_doc_step_10_b": "Update credentials",
  "azure_doc_step_11_a": "Select a user",
  "azure_doc_step_11_b": "and ",
  "azure_doc_step_11_c": "click on the select button to add them to an assignment",
  "azure_doc_step_13_a": "Select the user",
  "azure_doc_step_13_b": " you want ",
  "azure_doc_step_13_c": "to use depending on the group and its role",
  "azure_doc_step_12": "Congratulations, you have just completed the Azure AD provisioning configuration via SCIM.",
  "modal_change_password_title": "Reset password",
  "modal_change_password_description": "Before you proceed, it is necessary to change your password",
  "label_password_updated": "Password updated",
  "label_password_is_not_same": "The two passwords do not match",
  "btn_edit_password": "Edit",
  "label_password": "Password",
  "label_current_password": "Current password",
  "title_new_button": "News",
  "choose_title_theme": "Your individual newsletter - click for the latest news!",
  "subtitle_theme": "Select your industry or topic here.",
  "title_button_theme": "Discover my newsfeed",
  "back": "Back",
  "bloc_title_image": "About this article",
  "btn_see_article": "View article",
  "label_related_articles": "Related articles",
  "label_trashcan": "Trash",
  "page_trashcan_title": "Deleted entries",
  "label_clients": "Clients",
  "label_projects": "Projects",
  "deleted_user_table_head_name": "Client",
  "deleted_user_table_head_project_name": "Project name",
  "deleted_user_table_head_delete_date": "Deletion date",
  "deleted_user_table_head_project_count": "Number of projects",
  "deleted_user_table_head_task_count": "Number of tasks",
  "label_delete_by": "Deleted by: ",
  "action_delete_forever": "Permanently delete",
  "action_restore": "Restore",
  "restore_dialog_confirm": "Confirmation",
  "restore_dialog_message": "You are about to restore one or more clients. Their projects and settings will be automatically reset to the configuration before deletion.",
  "restore_project_dialog_message": "You are about to restore one or more projects. The tasks and settings will be automatically reset to the configuration before deletion.",
  "delete_clients_message": "Do you want to permanently delete this/these client(s)? This action cannot be undone.",
  "delete_projects_message": "Do you want to permanently delete this/these project(s)? This action cannot be undone.",
  "empty_trash_message": "No recently deleted items",
  "simple_delete_project_dialog_message": "Do you really want to delete the project?",
  "delete_project_success": "Project successfully deleted",
  "choose_person_to_lock": "Select person to lock",
  "locked_users_menu": "Locked users",
  "project_title_button_3": "News",
  "home_page_project_filter": "Sort by:",
  "home_filter_by_date_added": "Date added",
  "home_filter_by_deadline": "Deadlines",
  "home_filter_by_officialname": "Alphabetical order",
  "home_filter_by_completed_projects_percentage": "Percentage of completion",
  "home_filter_by_keine": "None",
  "project_page_title": "Projects",
  "page_close": "Close",
  "strong_password_error": "Your password must be at least 8 characters, contain an uppercase letter, a number, and a special character like @, +, =, $, #, ., _, ¨, ^",
  "signature_request_send_success": "Signature request successfully sent",
  "default_reminders": "Default reminders",
  "2_days_before": "2 days before",
  "3_week_before": "3 weeks before",
  "1_week_before": "1 week before",
  "4_week_before": "4 weeks before",
  "2_week_before": "2 weeks before",
  "6_week_before": "6 weeks before",
  "label_tasks": "Tasks",
  "label_groups": "Groups",
  "show_project_task": "Show",
  "message_view_task": "Do you want to make this task(s) or task group(s) visible?",
  "view_task_title": "View task",
  "details_project_page_title": "Project details",
  "default_doc_or_commment_reminders": "Reminder rate for comments and documents",
  "document_or_comment_daily": "Daily",
  "document_or_comment_weekly": "Weekly",
  "document_or_comment_monthly": "Monthly",
  "document_or_comment_never": "Never",
  "create_personal_template_button": "Create project as template",
    "notified_users": "Assign task to a user",
    "choose_person_to_be_notified": "Choose person to be notified",
    "sign_in_with_microsoft": "Sign in with Microsoft",
    "add_new_benetzur_visibility": "Add user",
    "user_client_exist": "Existing users",
    "manage_users_menu": "Locked client",
    "payload_general_templates_created_with_success": "General template created successfully",
    "move_task_title": "Move task titled {{name}}",
    "actual_version": "Current version",
    "new_features": "New features",
    "delete_group_template": "This task is the last one in its task group. Deleting it will also delete this task group.",
    "continue_with_risk_text": "Continue with risk?",
    "move_task_title_button": "Move task",
    "update_for": "Update for",
    "not_move_warning_message": "Unfortunately, you cannot move a task within the same group.",
    "all_projects": "All clients",
    "favorites_projects": "My clients",
    "task_deadline_filter": "My tasks",
    "global_menu": "Menu",
    "authorization_title_btn_1": "Permissions overview",
    "authorization_title_btn_2": "Permissions management",
    "select_client": "Select a client",
    "select_mandant": "Select client",
    "authorization_delete_confirm": "Do you want to revoke access to this task or task group?",
    "add_task_data_label_title": "Create new folder",
    "move_tasklist_title": "Drag and drop",
    "disabled_title": "Lock",
    "add_visibility_title_button": "Confirm",
    "make_task_done": "Task done",
    "global_label_confirm": "Confirm",
    "message_modal_confirm_task_done": "Are you sure you want to mark this task as done?",
    "my_task_filter": "My tasks",
    "due_date_filter": "My deadlines",
    "my_task_wihtout_deadline": "Tasks without deadline",
    "title_update_multiple_task_deadline": "Update deadlines of selected tasks",
    "other_action": "Other actions",
    "done_by_mandanten": "Assigned tasks Completed",
    "confirm_delete_comment": "Delete as",
    "message_delete_comment": "Are you sure you want to delete this comment?",
    "task_for_the_day": "Task for the day",
    "document_title": "Documents",
    "document_title_project": "Documents {{name}}",
    "no_data_home_page_message": "You currently have no access to clients",
    "not_take_it_change": "Sorry! This extension is not supported",
    "list_of_user_title": "List of users",
    "impersonate": "Impersonate",
    "message_impersonate": "Do you really want to impersonate: {{user}}",
    "message_impersonate_connected": "You are now logged in as: {{user}}",
    "document_preview": "Preview",
    "double_2FA_title": "Two-Factor Authentication",
    "translate_key_receive_mail_title": "Receive mail",
    "double_2FA_current_status": "Current status: {{enable}}",
    "translate_key_receive_mail_current_status": "Current state {{enable}}",
    "double_2FA_enable_switch": "{{activate}} Enable two-factor authentication",
    "translate_key_receive_mail_enable_switch": "Receive emails {{activate}}",
    "double_2FA_description": "Use a one-time password authenticator on your mobile device or computer to activate two-factor authentication (2FA). We recommend cloud-based mobile authentication apps like Authy, Google Authenticator, Duo Mobile, and LastPass. You can recover access if you lose your hardware device.",
    "double_2FA_code": "Please enter the code you received in your app",
    "double_2FA_submit": "Sign in with two-factor app",
    "translate_key_active": "Activate",
    "translate_key_active_2": "Activated",
    "translate_key_disable": "Disable",
    "translate_key_disable_2": "Disabled",
    "translate_key_phonenumber": "Please enter your phone number",
    "translate_key_password_confirm": "Please enter your password",
    "text_of_confirm_change_2FA_statut_to_desactivate": "Are you sure you want to deactivate two-factor authentication for this account?",
    "translate_key_role_forward": "Roll forward",
    "translate_key_name_project": "Project name",
    "translate_key_forward_with_document": "With documents",
    "translate_key_forward_without_document": "Without documents",
    "translate_key_description_project": "Project description",
    "translate_key_title_of_forward": "Select the task and task list of your new project name: {{name}}",
    "translate_key_forward_ok": "Forward",
    "translate_key_last_uploaded_document": "Last uploaded document",
    "translate_key_document_edited": "Edit document",
    "translate_key_error_forward": "You must select at least one task list",
    "translate_key_document_downloaded_selected": "{{length}} Selected documents",
    "translate_key_see_all_document": "Show more documents",
    "translate_key_see_all_document_active": "Show less",
    "translate_key_roll_forward_subtitle": "You are about to create a new project based on this project",
    "translate_key_roll_forward_selected_document": "Select the tasks and documents you want to transfer to the new project.",
    "translate_key_add_existing_user": "Add existing user",
    "translate_key_document_in_task": "Document from {{name}}",
    "translate_key_all_selected": "Select all",
    "translate_key_save": "Save",
    "translate_key_receive_mail_warning_title": "Warning",
    "translate_key_receive_mail_warning_description": "If you confirm that you have disabled email reception, you will no longer receive emails!",
    "translate_key_receive_mail_warning_description_2": "Are you sure you want to {{statut}}?",
    "translate_key_not_data": "Unfortunately, there is currently no data available.",
    "translate_key_message_success_receive_mail": "Successful change made",
    "translate_key_all_document_version": "All versions of the document {{name}}",
    "translate_key_no_document_retrive": "No document found",
    "translate_key_restore": "Restore",
    "translate_ikey_text_restore": "Do you want to restore the file? This action cannot be undone.",
    "translate_key_delete_version_title": "Delete Document",
    "translate_key_delete_version_message": "Are you sure you want to delete this version of the document? This action is irreversible.",
    "translate_key_document_drag": "Drag and drop doc here or",
    "translate_key_document_type": "All types of documents (Word, PDF, Excel, etc...).",
    "translate_key_document_browser_file": "Browse file",
    "translate_key_download_form_title": "Upload documents from",
    "translate_key_my_computer": "My computer",
    "translate_key_datev_tile": "Datev",
    "translate_key_download_success": "Download successfully completed",
    "translate_key_import_document": " Import document",
    "translate_key_invitation_send": "Invitation successfully sent",
    "translate_key_invitation_user": "Invite PKF user",
    "translate_key_invitation_title": "Invite user to project {{project}}",
    "translate_key_my_invitation_title": "My invitations",
    "translate_key_invitation_title_setting": "Manage invitations",
    "translate_key_received": "Received",
    "translate_key_ACCEPTED": "Accepted",
    "translate_key_REJECTED": "Rejected",
    "translate_key_PENDING": "Pending",
    "translate_key_DELETED": "Deleted",
    "translate_key_client_title": "Invite PKF users from other firms to client",
    "translate_key_success_mail": "An email has been sent to the address {{email}}",
    "translate_key_save_template": "Save modified template",
    "translate_key_save_copy_template": "Save as copy template",
    "translate_key_invite_user_to_project": "Invite PKF users from other firms to project",
    "project_completion": "Percentage completed",
    "assign_task": "Assign task",
    "confirm_task_delete": "Are you sure you want to delete this task?",
    "add__new_user": "Add client user",
    "added_to_favorites": "Added to favorites",
    "clear": "Reset",
    "invite_user_to_sign": "Invite added customers to sign",
    "german": "Deutsch",
    "english": "English",
    "my_task_deadline": "Tasks with deadline",
    "text_all_clients_page": "Here you can see all currently active clients",
    "text_view_active_pkf_employess": "Here you can see all currently active PKF employees who have access to the PKF client portal.",
    "invitation_not_found": "Invitation not found",
    "about_pkf_germany": "PKF Germany is one of the most efficient independent auditing and consulting networks in Germany. Our range of services includes, in addition to the classic services around auditing and tax consulting, especially individual consulting services in the areas of corporate finance and digitalization. At 39 locations in Germany, we create proximity to our clients and at the same time offer access to our global network in 144 countries.",
    "invite_user_placeholder": "Please provide the email address of the user",
    "datev_filter_title": "Please select a filter to search for documents",
    "datev_ordner": "Folder",
    "datev_register": "Register",
    "datev_state": "State",
    "datev_id_error": "Client has no connection to DATEV",
    "my_tasks": "My Tasks",
    "assigned_to": "Assigned tasks to",
    "completed_assigned": "Completed assigned task",
    "yes": "Yes",
    "no": "No",
    "search": "Search",
    "year": "Year",
    "no_more_docs": "There is no more documents to show",
    "current_client": "Current client",
    "other_client": "Other client",
    "search_mandant": "Search client",
    "select_all_docs": "Select all documents",
    "select_all_tasks": "Select all tasks"
  },
}

